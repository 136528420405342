<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Список остановленных передач</template>
        <template #content>
          <AbstractTable code="sendStops" display-filter="menu"></AbstractTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
export default {
  name: "StoppedLoadsList",
  components: {AbstractTable}
}
</script>

<style scoped>

</style>